const React = require("react")
const { Auth0ContextProvider } = require("./src/context/Auth0ContextProvider")
const { AppMessageProvider } = require("./src/context/AppMessage")

exports.wrapRootElement = ({ element }) => {
  return (
    <Auth0ContextProvider>
      <AppMessageProvider>{element}</AppMessageProvider>
    </Auth0ContextProvider>
  )
}
