import React from "react"
import { Auth0Provider } from "@auth0/auth0-react"

const getRedirectUri = () => {
  let url = "https://www.sonesta.com/callback"
  if (typeof window !== "undefined") {
    url = `${window.location?.origin}/callback`
  } else if (!!process.env.GATSBY_AUTH0_CALLBACK) {
    url = process.env.GATSBY_AUTH0_CALLBACK
  }
  return url
}

export const Auth0ContextProvider = props => {
  const { children } = props

  return (
    <Auth0Provider
      domain={process.env.GATSBY_AUTH0_DOMAIN || "auth.sonesta.com"}
      clientId={
        process.env.GATSBY_AUTH0_CLIENTID || "FKrEvaRK4c3EPLbtR3vMb5nQBmL7a6Eh"
      }
      redirectUri={getRedirectUri()}
    >
      {children}
    </Auth0Provider>
  )
}
