import React, { useState, useContext, createContext } from "react"

export interface Message {
  messageType: "success" | "error" | ""
  messageContent: string
}

export interface AppMessageContextState {
  message: Message
  setMessage: (message: Message) => void
  resetMessage: () => void
}
const initialState: AppMessageContextState = {
  message: {
    messageType: "",
    messageContent: "",
  },
  setMessage: (msg: Message) => {},
  resetMessage: () => {},
}

export const AppMessageContext = createContext(initialState)
export const useAppMessage = () => useContext(AppMessageContext)

export const AppMessageProvider = (props: any) => {
  const { children } = props
  const [message, setMessage] = useState(initialState.message)

  const resetMessage = () => {
    setMessage({
      messageType: "",
      messageContent: "",
    })
  }

  return (
    <AppMessageContext.Provider
      value={{
        message,
        setMessage,
        resetMessage,
      }}
    >
      {children}
    </AppMessageContext.Provider>
  )
}
